export default [
    {
        title: 'الرئيسية',
        route: 'home',
        icon: 'HomeIcon',
    }
    , {
        title: 'شاشة الفحص',
        route: 'scan',
        icon: 'MonitorIcon',
    },
    {
        title: 'التحكم بالمعلمين',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title: 'استعراض المعلمين',
                route: 'teachers',
            },
            {
                title: 'إضافة معلم جديد',
                route: 'addTeacher',
            },
            {
                title: 'استيراد المعلمين',
                route: 'importTeacher',
            },
        ],
    },
    {
        title: 'تقارير المعلمين',
        icon: 'PieChartIcon',
        tagVariant: 'light-warning',
        children: [
            {
                title: 'الحضور والغياب والتأخر',
                route: 'reports',
            },
            {
                title: 'الحضور والغياب.. لفترة',
                route: 'reportsBetween',
            },
            {
                title: 'إعادة رفع تقارير البصمة',
                route: 'reportsReupload',
            },
            {
                title: 'الإجازات',
                route: 'reportsFreedays',
            },
        ],
    },
    {
        title: 'المناوبة والإشراف',
        route: 'shifts',
        icon: 'CalendarIcon',
    },
    {
        title: 'أجهزة البصمة',
        route: 'fingerprint',
        icon: 'ServerIcon',
    },
    {
        title: 'إعدادات المعلمين',
        route: 'settings',
        icon: 'SettingsIcon',
    },
    {
        title: 'الإعدادات العامة',
        route: 'settingsGeneral',
        icon: 'SettingsIcon',
    },
]