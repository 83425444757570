<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://brmja.com" target="_blank"
        >brmja.com</b-link
      >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      تاريخ اليوم الميلادي: <span dir="ltr">{{ date }}</span>
      | تابعة لنظام
      <a href="https://tahdir.net" target="_blank">التحضير الذكي</a>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";
import moment from "moment-timezone";
export default {
  components: {
    BLink,
  },
  data() {
    return {
      date: null,
    };
  },
  created() {
    this.date = moment().format("YYYY-MM-DD");
  },
};
</script>
